<template>
  <div class="">
    <promo-section
      class="promo--careers"
      :subtitle="promo.subtitle"
      :title="promo.title"
      :image="promo.image"
      :button="promo.button"
    />
    <positions-section />
  </div>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import PositionsSection from '@/components/careers-page/PositionsSection.vue';
import promoImage from '@/assets/images/careers-promo.svg';

export default {
  name: 'Careers',
  components: {
    PromoSection,
    PositionsSection,
  },
  data() {
    return {
      promo: {
        subtitle: 'Careers',
        title: 'Your road to an exciting career <span class="text-accent">starts here.</span>',
        image: promoImage,
        button: {
          caption: 'JOIN THE TEAM',
          icon: 'team',
          link: '#positions',
          route: true,
        },
      },
    };
  },
};
</script>
