<template>
  <div
    class="positions"
    id="positions"
  >
    <div class="positions__container container">
      <h2 class="positions__title heading" v-html="title"/>
      <div class="positions__row">
        <div
          v-for="position in positions"
          :key="position.id"
          class="positions__col"
        >
          <position-card class="positions__card" :position="position"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PositionCard from '@/components/careers-page/PositionCard.vue';

export default {
  name: 'PositionsSection',
  components: { PositionCard },
  data() {
    return {
      title: 'Open Positions',
      positions: [
        {
          id: 1,
          color: '#1CB0FF',
          gradientFrom: '#4560FF',
          gradientTo: '#0EC9FE',
          title: 'Visual Design Intern',
          term: 'January / February 2022',
          abbr: 'Vd',
          link: 'virtual-design-intern',
        },
        {
          id: 2,
          color: '#9929E9',
          gradientFrom: '#A931E5',
          gradientTo: '#4F04FE',
          title: 'Javascript Developer',
          term: 'January / February 2022',
          abbr: 'Js',
          link: 'javascript-developer',
        },
        {
          id: 3,
          color: '#1EC9AB',
          gradientFrom: '#16B2B2',
          gradientTo: '#2CF29F',
          title: 'PHP Developer',
          term: 'January / February 2022',
          abbr: 'Ph',
          link: 'php-developer',
        },
        {
          id: 4,
          color: '#1CB0FF',
          gradientFrom: '#4560FF',
          gradientTo: '#0EC9FE',
          title: 'Data Analyst',
          term: 'January / February 2022',
          abbr: 'Da',
          link: 'data-analyst',
        },
        {
          id: 5,
          color: '#1CB0FF',
          gradientFrom: '#4560FF',
          gradientTo: '#0EC9FE',
          title: 'A.I. Engineer',
          term: 'January / February 2022',
          abbr: 'Ai',
          link: 'ai-engineer',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";

.positions {
  padding: 100px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $sm) {
    padding: 35px 0 25px;
  }

  &__title {

    @include breakpoint(0, $sm) {
      display: none;
    }

  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    max-width: 1240px;
    margin: 0 auto;

    @include breakpoint(0, $xlg) {
      grid-template-columns: 1fr 1fr;
      padding: 0;
      grid-gap: 30px;
    }

    @include breakpoint(0, $md) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

}
</style>
