<template>
  <div
    class="position-card"
    :style="bgColor"
  >
    <div class="position-card__abbr" v-text="position.abbr" />
    <div class="position-card__content">
      <h4 class="position-card__title" v-text="position.title" />
      <div class="position-card__term" v-text="position.term" />

      <div class="position-card__actions">
        <router-link class="position-card__link btn--link" :to="{ name: 'CareersSingle', params: { position: position.link } }" >
          Learn More
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PositionCard',
  props: {
    position: Object,
  },
  computed: {
    bgColor() {
      return {
        backgroundColor: this.position.gradientFrom,
        background: `linear-gradient(135deg, ${this.position.gradientFrom} 0%, ${this.position.gradientTo} 100%)`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.position-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 25px;
  background-color: $blue;
  background: linear-gradient(135deg, #4560FF 0%, #0EC9FE 100%);
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);

  &__content {
    position: relative;
    z-index: 5;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 24px;
    color: $light;
    font-weight: 400;

    @include breakpoint(0, $lg) {
      font-size: 22px;
    }
  }

  &__term {
    font-size: 13px;
    color: $light;
    padding-bottom: 100px;

    @include breakpoint(0, $lg) {
      font-size: 12px;
    }

  }

  &__link {
    font-size: 13px;
    color: $light;
    text-decoration: none;
  }
  &__abbr {
    position: absolute;
    right: 20px;
    bottom: 0;
    z-index: 2;
    font-size: 185px;
    line-height: 0.7;
    color: rgba(255, 255, 255, 0.08);
  }

}
</style>
